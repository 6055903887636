import React, { useContext } from 'react'
import { SocketContext } from '../Context/SocketContext'

function useSocket() {
  const socket = useContext(SocketContext);

  

  return socket;
}

export default useSocket
