import BuildOutlinedIcon from "@mui/icons-material/BuildOutlined";
import CurrencyRupeeOutlinedIcon from "@mui/icons-material/CurrencyRupeeOutlined";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import embIco from "../../assets/embifi-logo.png";
import Autocomplete from "../AutoComplete/AutoComplete";
import { toolsList } from "../../Constants/toolsList";
import { UserContext } from "../../Context/UserContext";
import useAuth from "../../hooks/useAuth";
import SpokeIcon from "@mui/icons-material/Spoke";
import PeopleIcon from "@mui/icons-material/People";
import NotificationsIcon from "@mui/icons-material/Notifications";
import PaymentsIcon from "@mui/icons-material/Payments";
import RoomIcon from "@mui/icons-material/Room";
import { Badge, IconButton } from "@mui/material";
import Hamburger from "hamburger-react";
import React, { useContext, useEffect, useState } from "react";
import { IoMdAnalytics } from "react-icons/io";
import { IoMapSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "../../Context/GlobalContext";
import { getNotification } from "../../api";
import shineBg from "../../assets/bgShine.png";
import { APP_VERSION } from "../../config";
import "./SideNavBar.css";

const SideNavBar = ({ children, active, isClosed }) => {
  const [open, setOpen] = useState(true);
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const { notiCountState } = useContext(GlobalContext);
  const [notiCount, setNotiCount] = notiCountState;
  const { logoutUser } = useAuth();

  useEffect(() => {
    if (isClosed) {
      setOpen(false);
    }
  }, [isClosed]);

  function notificationsLabel(count) {
    if (count === 0) {
      return "no notifications";
    }
    if (count > 99) {
      return "more than 99 notifications";
    }
    return `${count} notifications`;
  }

  const NotificationButton = () => {
    return (
      <IconButton
        aria-label={notificationsLabel(notiCount || 0)}
        onClick={() => {
          navigate("/notifications");
        }}
      >
        <Badge badgeContent={notiCount || 0} color="secondary">
          <NotificationsIcon sx={{ color: "white" }} />
        </Badge>
      </IconButton>
    );
  };

  const getNotificationCount = async () => {
    try {
      let { data } = await getNotification(`count=true`);
      setNotiCount(data?.count || 0);
    } catch (err) {}
  };

  useEffect(() => {
    getNotificationCount();
  }, []);

  return (
    <>
      <div
        className={`sidebar-home ${open && "open"}`}
        style={{ backgroundImage: `url(${shineBg})` }}
      >
        <div className="logo-details">
          <i className="icon me-3">
            <img
              src={embIco}
              alt="logo"
              style={{
                width: open ? "110px" : 0,
                transition: "width 0.3s ease",
              }}
            />
          </i>
          {open && <NotificationButton />}
          <i
            id="btn"
            onClick={() => {
              setOpen(!open);
            }}
            style={{ cursor: "pointer", marginTop: "7px" }}
          >
            <Hamburger toggled={open} toggle={setOpen} size={20} />
          </i>
        </div>
        <ul className="nav-list">
          {!open && (
            <div className="d-flex justify-content-center">
              <NotificationButton />
            </div>
          )}
          <li>
            <i
              className="bx bx-search"
              onClick={() => {
                if (!open) {
                  setOpen(!open);
                }
              }}
            ></i>
            {/* <input type="text" placeholder="Search..." /> */}
            <Autocomplete
              options={toolsList}
              onSubmit={(val) => {
                navigate(val?.link);
              }}
            />
            <span className="tooltip">Search</span>
          </li>
          {Number(user?.role) === 1 && (
            <li>
              <a
                onClick={() => {
                  navigate("/dashboard");
                }}
                className={`${active === "dashboard" && "sidebar-active"}`}
              >
                <i className="bx bx-grid-alt"></i>
                <span className="links_name">Dashboard</span>
              </a>
              <span className="tooltip">Dashboard</span>
            </li>
          )}
          {user?.user_type !== "NBFC" && Number(user?.role) !== 3 && (
            <li>
              <a
                onClick={() => {
                  navigate("/tools");
                }}
                className={`${active === "tools" && "sidebar-active"}`}
              >
                <i className="">
                  <BuildOutlinedIcon sx={{ fontSize: "20px" }} />
                </i>
                <span className="links_name">Quick Tools</span>
              </a>
              <span className="tooltip">Quick Tools</span>
            </li>
          )}
          <li>
            <a
              onClick={() => {
                navigate("/loan-applications");
              }}
              className={`${
                active === "loan-applications" && "sidebar-active"
              }`}
            >
              <i class="bx bx-file"></i>
              <span className="links_name">Loan Applications</span>
            </a>
            <span className="tooltip">Loan Applications</span>
          </li>
          {[1, 5].includes(Number(user?.role)) && user?.departments?.some(dep=>["SALES","CREDIT_CHECK"].includes(dep)) && (
            <li>
              <a
                onClick={() => {
                  navigate("/pendency-tracker");
                }}
                className={`${active === "pendancy-tracker" && "sidebar-active"}`}
              >
                <i className="">
                  <PeopleIcon sx={{ fontSize: "20px" }} />
                </i>
                <span className="links_name">Pendency Tracker</span>
              </a>
              {/* <span className="tooltip">Pendency Tracker</span> */}
            </li>
          )}
          <li>
            <a
              onClick={() => {
                navigate("/map-view-satellite");
              }}
              className={`${
                active === "map-view-satellite" && "sidebar-active"
              }`}
            >
              <i>
                <IoMapSharp sx={{ fontSize: "20px" }} />
              </i>
              <span className="links_name">Map View</span>
            </a>
            <span className="tooltip">Map View</span>
          </li>
          <li>
            <a
              onClick={() => {
                navigate("/collections-v2");
              }}
              className={`${active === "collections-v2" && "sidebar-active"}`}
            >
              {/* <i className="bx bx-pie-chart-alt-2"></i> */}
              <i className="">
                <CurrencyRupeeOutlinedIcon sx={{ fontSize: "20px" }} />
              </i>
              <span className="links_name">Collections</span>
            </a>
            <span className="tooltip">Collections</span>
          </li>

          {[1, 5].includes(Number(user?.role)) && (
            <li>
              <a
                onClick={() => {
                  navigate("/transactions-v2");
                }}
                className={`${
                  active === "transactions-v2" && "sidebar-active"
                }`}
              >
                <i className="">
                  <PaymentsIcon sx={{ fontSize: "20px" }} />
                </i>
                <span className="links_name">Transactions</span>
              </a>
              <span className="tooltip">Transactions</span>
            </li>
          )}

          {Number(user?.role) !== 3 && (
            <li>
              <a
                onClick={() => {
                  navigate("/gps");
                }}
                className={`${active === "gps" && "sidebar-active"}`}
              >
                <i className="">
                  <RoomIcon sx={{ fontSize: "20px" }} />
                </i>
                <span className="links_name">GPS</span>
              </a>
              <span className="tooltip">GPS</span>
            </li>
          )}

          {[2, 1, 5].includes(Number(user?.role)) && (
            <li>
              <a
                onClick={() => {
                  navigate("/partners");
                }}
                className={`${active === "partners" && "sidebar-active"}`}
              >
                <i className="">
                  <SpokeIcon sx={{ fontSize: "20px" }} />
                </i>
                <span className="links_name">Partners</span>
              </a>
              <span className="tooltip">Partners</span>
            </li>
          )}

          <li>
            <a
              onClick={() => {
                navigate("/agent-tracker");
              }}
              className={`${active === "agent-tracker" && "sidebar-active"}`}
            >
              <i className="">
                <IoMdAnalytics />
              </i>
              <span className="links_name">Agent Tracker</span>
            </a>
            <span className="tooltip">Agent Tracker</span>
          </li>

          {[1, "1"].includes(user?.role) && (
            <li>
              <a
                onClick={() => {
                  navigate("/users");
                }}
                className={`${active === "users" && "sidebar-active"}`}
              >
                <i className="">
                  <AccountTreeIcon sx={{ fontSize: "20px" }} />
                </i>
                <span className="links_name">Users</span>
              </a>
              <span className="tooltip">Users</span>
            </li>
          )}
          {/* <li>
            <a href="#">
              <i className="bx bx-folder"></i>
              <span className="links_name">File Manager</span>
            </a>
            <span className="tooltip">Files</span>
          </li>
          <li>
            <a href="#">
              <i className="bx bx-cart-alt"></i>
              <span className="links_name">Order</span>
            </a>
            <span className="tooltip">Order</span>
          </li>
          <li>
            <a href="#">
              <i className="bx bx-heart"></i>
              <span className="links_name">Saved</span>
            </a>
            <span className="tooltip">Saved</span>
          </li> */}
          <li>
            <a
              onClick={() => {
                navigate("/settings");
              }}
              className={`${active === "settings" && "sidebar-active"}`}
            >
              <i className="bx bx-cog"></i>
              <span className="links_name">Settings</span>
            </a>
            <span className="tooltip">Settings</span>
          </li>
          <li className="profile">
            <p className="app-version">Version {APP_VERSION}</p>
            <div className="profile-details">
              {/* <!--<img src="profile.jpg" alt="profileImg">--> */}
              <div className="name_job">
                <div className="name">{user?.name?.split(" ")?.[0]}</div>
                <div className="job" style={{ color: "#444167" }}>
                  {user?.user_id}
                </div>
              </div>
            </div>
            <i
              className="bx bx-log-out pointer mt-2"
              id="log_out"
              onClick={() => logoutUser()}
            ></i>
          </li>
        </ul>
      </div>
      <section className="home-section">
        <div>{children}</div>
      </section>
    </>
  );
};

export default SideNavBar;
