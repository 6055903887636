import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import Highcharts3D from "highcharts/highcharts-3d";
import highchartsMore from "highcharts/highcharts-more";
import highchartsSolidGauge from "highcharts/modules/solid-gauge";
import { FormControlLabel, Switch } from "@mui/material";

Highcharts3D(Highcharts);
highchartsMore(Highcharts);
highchartsSolidGauge(Highcharts);

const ChartDonut2 = ({ data }) => {
  const [chartData, setChartData] = useState({
    title: "Expenditure",
    data: [
      { name: "Less than 0", y: 13, color: "#4285f4", perc: "81.25%" },
      { name: "0 to 15", y: 0, color: "#ea4335", perc: "0%" },
      { name: "15 to 30", y: 1, color: "#fbbc04", perc: "6.25%" },
      { name: "30 to 45", y: 0, color: "#34a853", perc: "0%" },
      { name: "45 to 60", y: 1, color: "#ff6d01", perc: "6.25%" },
      { name: "60 to 75", y: 1, color: "#46bdc6", perc: "6.25%" },
      { name: "75 to 90", y: 0, color: "#7baaf7", perc: "0%" },
      { name: "90+", y: 0, color: "#f07b72", perc: "0%" },
    ],
  });

  const [showLabels, setShowLabels] = useState(false);

  useEffect(() => {
    createChart();
  }, [showLabels]);

  const toggleLabels = () => {
    setShowLabels(!showLabels);
  };

  const createChart = () => {
    Highcharts.chart("donut_chart", {
      chart: {
        type: "pie",
        options3d: {
          enabled: true,
          alpha: 45,
          beta: 0,
        },
      },
      title: { text: "" },
      subtitle: { text: "" },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          innerSize: 120,
          depth: 40,
        },
      },
      legend: {
        align: "right",
        verticalAlign: "middle",
        layout: "vertical",
        itemMarginTop: 2,
        itemMarginBottom: 2,
        itemStyle: {
          fontSize: 14,
          font: "Arial, Helvetica, sans-serif",
          color: "#333333",
          fontWeight: "normal",
        },
      },
      tooltip: {
        valueSuffix: "%",
        useHTML: true,
        formatter: function () {
          return `<b>${this.point.y}</b> (${this.point.perc})`;
        },
      },
      series: [
        {
          name: null,
          data: chartData.data,
          dataLabels: {
            enabled: showLabels,
            format: "{point.y} ({point.perc})",
          },
          showInLegend: true,
        },
      ],
      responsive: {
        rules: [
          {
            condition: { maxWidth: 450 },
            chartOptions: {
              legend: {
                title: {
                  style: {
                    fontSize: 14,
                    lineHeight: 16,
                  },
                },
                align: "center",
                verticalAlign: "bottom",
                layout: "horizontal",
                itemDistance: 10,
                maxHeight: 120,
                itemStyle: {
                  fontSize: 12,
                },
              },
            },
          },
        ],
      },
      credits: {
        enabled: false,
      },
    });
  };

  return (
    <div className="chart-container">
      <div className="d-flex justify-content-end">
        <FormControlLabel
          control={
            <Switch
              checked={showLabels}
              onChange={(event) => {
                setShowLabels(event.target.checked);
              }}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          label="Label data"
        />
      </div>

      <div id="donut_chart" className="bar-chart-container" />
    </div>
  );
};

export default ChartDonut2;
