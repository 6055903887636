import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import React from "react";
import DonutChart from "./Demo/DemoPie";
import { styled } from "@mui/material/styles";
import DonutChart2 from "./Demo/DemoPie2";
import { useEffect } from "react";
import ExcelTable from "./Demo/DataTable";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const PieDialog = ({ open, type, title, handleClose }) => {
  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        {title}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <div style={{ width: "80%" }}>
            {type === "amount" && <DonutChart />}
            {type === "file" && <DonutChart2 />}
            {type === "table" && <ExcelTable />}
          </div>
        </div>
      </DialogContent>
    </BootstrapDialog>
  );
};

export default PieDialog;
