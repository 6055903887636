// src/components/CustomAvatarCarousel.js
import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { IconButton } from '@mui/material';
import { ArrowBack, ArrowForward } from '@mui/icons-material';

const CustomCarousel = ({ avatars }) => {
  return (
    <Carousel
    //   nextIcon={
    //     <IconButton>
    //       <ArrowForward />
    //     </IconButton>
    //   }
    //   prevIcon={
    //     <IconButton>
    //       <ArrowBack />
    //     </IconButton>
    //   }
        indicators={false}
    >
      {avatars.map((avatar, index) => (
        <Carousel.Item key={index} interval={1000}>
          {avatar}
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default CustomCarousel;