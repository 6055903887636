import { CreditRemark } from "../Components/StatusChip";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import BlockIcon from "@mui/icons-material/Block";
import moment from "moment";

export const f = (value, type = "text", dark = false) => {
  if ((!value && type !== "bool") || ( type === "bool" && [undefined,null].includes(value))) {
    return <NotAvailable dark={dark} />;
  }
  if (value !== "" || value !== null) {
    if (type === "cur") {
      return "₹ " + Number(value)?.toLocaleString("en-IN");
    } else if (type === "rate") return value + "%";
    else if (type === "km") return value + " km";
    else if (type === "bool") {
      if (value)
        return (
          <span style={{ color: "green" }}>
            <CheckCircleOutlineIcon style={{ fontSize: "17px" }} /> Yes
          </span>
        );
      return (
        <span style={{ color: "red" }}>
          <BlockIcon style={{ fontSize: "17px" }} /> No
        </span>
      );
    } else if (type === "date/time") {
      return (
        <span>
          {moment(value).format("DD/MM/YYYY")}
          <span
            style={{ fontSize: 10, color: "rgba(57, 57, 57, 1)" }}
          >{` (${moment(value).format("hh:mm A")})`}</span>
        </span>
      );
    } else if (type === "date") {
      return <span>{moment(value).format("DD/MM/YYYY")}</span>;
    } else return value;
  } else {
    return <NotAvailable dark={dark} />;
  }
};

const NotAvailable = ({ dark }) => {
  return (
    <span
      style={
        !dark
          ? {
              color: "#000",
              backgroundColor: "#e8e8e8",
              fontSize: "10px",
              padding: "2px 10px",
              borderRadius: "5px",
            }
          : {
              color: "#474463",
              backgroundColor: "#1d1b31",
              fontSize: "10px",
              padding: "2px 10px",
              borderRadius: "5px",
            }
      }
    >
      NA
    </span>
  );
};

export const scoreCheckText = (score) => {
  // new to credit
  if (!score || score === null) return <NotAvailable />;
  if (score < 300)
    return <CreditRemark value={"New to Credit"} color="#e0e0e0" />;
  // poor
  else if (score >= 300 && score < 580)
    return <CreditRemark value={"Poor"} color="#FFB200" />;
  // fair
  else if (score >= 580 && score <= 669)
    return <CreditRemark value={"Fair"} color="#FFB200" />;
  // good
  else if (score >= 670 && score <= 739)
    return <CreditRemark value={"Good"} color="#3399ff" />;
  // very good
  else if (score >= 740 && score <= 799)
    return <CreditRemark value={"Very Good"} color="#66cc00" />;
  // exceptional
  else if (score > 800)
    return <CreditRemark value={"Exceptional"} color="#3b8e24" />;
};

export const responseFormat = (text, status) => {
  if (!text) return <NotAvailable />;
  if (text === "" || text === null) return <NotAvailable />;
  return <span style={{ color: status ? "blue" : "blue" }}>{text}</span>;
};

export const fStatus = (value) => {
  if (!value) {
    return <NotAvailable />;
  }

  if (typeof value === "object" && value !== null) {
    return convertDisplay(value);
    // return (
    //   <>
    //     <div>
    //       <span>sefwe</span>
    //       <span>setg</span>
    //     </div>
    //     <div>
    //       <span>sefwe</span>
    //       <span>setg</span>
    //     </div>
    //   </>
    // );
  }

  if (typeof value === "string") {
    return value?.replaceAll("_", " ");
  }
};

const convertDisplay = (value) => {
  return (
    <span>
      {Object.keys(value)?.map((key) => {
        return (
          <div>
            <span
              style={{ color: "gray", marginRight: "10px", fontSize: "10px" }}
            >
              - {key}:
            </span>
            <span style={{ fontSize: "12px", fontWeight: 500 }}>
              {typeof value[key] === "object" ? (
                <div className="ps-2">{convertDisplay(value[key])}</div>
              ) : (
                fStatus(value[key])
              )}
            </span>
          </div>
        );
      })}
    </span>
  );
};

export const formatDate = (dateString) => {
  var dateParts = dateString.split("/");
  return new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
};

export const cf = (val, round = false) => {
  val = Number(val);
  if (!isNaN(val)) {
    return round
      ? `₹${Math.ceil(val)?.toLocaleString("en-IN")}`
      : `₹${val?.toLocaleString("en-IN")}`;
  } else {
    return "NA";
  }
};

export const capitalize = (val, separationStr = " ") => {
  if (
    typeof val !== "object" &&
    val !== undefined &&
    val !== null
    // && val === String
  ) {
    let arr_temp = val?.split(separationStr);
    let str_arr = [];

    arr_temp.forEach((edx, ind) => {
      let tmp = edx?.charAt(0)?.toUpperCase() + edx?.slice(1)?.toLowerCase();
      str_arr = [...str_arr, tmp];
    });
    return str_arr?.join(" ");
  }
};

export const fCollectionStatus = (value) => {
  let color = "";
  let text = "";
  let bg = "";

  switch (value) {
    case "PAID":
      color = "green";
      bg = "#ECFDF3";
      text = "Paid";
      break;
    case "PAID_PARTIALLY":
      color = "green";
      bg = "#ECFDF3";
      text = "Partially Paid";
      break;
    case "DELAY":
      color = "#EF4444";
      bg = "#FFE3E0";
      text = "Delay";
      break;
    case "ADVANCE":
      color = "#9A309F";
      bg = "#F7D1F9";
      text = "Advance";
      break;
    case "ADVANCE_PARTIALLY":
      color = "#9A309F";
      bg = "#F7D1F9";
      text = "Advance";
      break;
    case "PENDING":
      color = "#B97524";
      bg = "#F9F3D1";
      text = "Pending";
      break;
  }

  return (
    <>
      <span
        style={{
          color: color,
          fontWeight: 500,
          fontSize: 10,
          background: bg,
          padding: "5px 20px",
          borderRadius: "30px",
        }}
      >
        {text ? <>&bull; {text}</> : "-"}
      </span>
    </>
  );
};

export function getDocKey(link) {
  const url = new URL(link);
  const params = new URLSearchParams(url.search);
  return {
    key: params.get("key"),
    dataType: params.get("dataPart")?.split(";")?.[0]?.replaceAll("data:", ""),
  };
}

export function blobToBase64(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64String = reader.result;
      resolve(base64String);
    };
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
}

export async function base64ToPdf(base64String, filename) {
  const byteCharacters = atob(base64String);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);

  const blob = new Blob([byteArray], { type: "application/pdf" });
  const url = URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = url;
  link.download = filename;
  link.click();

  URL.revokeObjectURL(url);
}

export function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}
