import React, { useEffect } from "react";
import { createPortal } from "react-dom";
import {
  DndContext,
  DragOverlay,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { SortableContext, useSortable } from "@dnd-kit/sortable";
import { useMemo } from "react";
import { CSS } from "@dnd-kit/utilities";
import { useState } from "react";
import { useDraggable } from "@dnd-kit/core";
import InfiniteScroll from "react-infinite-scroll-component";
import { LoadingComp } from '../Dialogs/Notifications';
/**
 *
 * @param {columnData, columnHieght,columnIds,TaskIds, TaskBox,} param0
 * @returns JSX
 */
function KanbanBoard({ columnsData:columns, columnHieght, boardData, setBoardData, handleDragOver, handleDragEndFun }) {
  const [activeColumn, setActiveColumn] = useState(null);
  const [activeTask, setActiveTask] = useState(null);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 3,
      },
    })
  );

  const columnsIds = useMemo(
    () => columns?.map((itm) => itm?.label), // later if needed replace with unique id's
    [columns]
  );

  function handleDragEnd(event) {
    const { active, over } = event;

    if (!over) return;

    handleDragEndFun(activeTask,over?.data?.current)
    setActiveColumn(null);
    setActiveTask(null);
  }

  function handleDragStart(event) {
    if (event?.active?.data?.current?.type === "Column") {
      setActiveColumn(event?.active?.data?.current);
    }
    if (event?.active?.data?.current?.type === "Task") {
      let taskData = event?.active?.data?.current;
      taskData["idx"] = boardData.findIndex(itm=>itm?.application_id===taskData?.taskData?.application_id);
      setActiveTask(taskData);
    }
  }

  return (
    <div id="kanban-board-body" className="h-100">
      <DndContext sensors={sensors} onDragStart={handleDragStart} onDragOver={handleDragOver} onDragEnd={handleDragEnd}>
        <div className="d-flex flex-row h-100  w-100 overflow-auto">
          <SortableContext items={columnsIds}>
            {columns?.map((clmData, index) => {
              return (
                <ColumnContainer
                  key={index}
                  width={clmData?.width}
                  label={clmData?.label}
                  columnHieght={columnHieght}
                  columnData={clmData}
                  tasks={boardData?.filter(clmData?.filterFun) || []}
                  columnIdx={index}
                />
              );
            })}
          </SortableContext>
        </div>
        {createPortal(
          <DragOverlay>
            {activeColumn && <ColumnContainer {...activeColumn} />}
            {activeTask && <TaskCard {...activeTask} />}
          </DragOverlay>,
          document.body
        )}
      </DndContext>
    </div>
  );
}

function ColumnContainer({ width, label, columnHieght, columnData, tasks }) {
  const tasksIds = useMemo(() => tasks?.map(columnData?.getId), [tasks]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);

  const {
    setNodeRef,
    attributes,
    listeners,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: columnData?.label,
    data: {
      type: "Column",
      width,
      label,
      columnHieght,
      columnData,
      tasks,
    },
  });

  const { draggableProps } = useDraggable({
    id: columnData?.label,
    disabled: true,
  });

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };

  // if (isDragging) {//uncomment when handling column swap
  //   return (
  //     <div
  //       ref={setNodeRef}
  //       className="h-100"
  //       style={{ ...style, marginRight: "20px", backgroundColor: "#E6EDFF" }}
  //     >
  //       <div
  //         className="border rounded h-100"
  //         style={{ width: width ?? "300px" }}
  //       ></div>
  //     </div>
  //   );
  // }

  return (
    <div
      {...draggableProps}
      ref={setNodeRef}
      className="h-100"
      style={{ ...style, marginRight: "20px" }}
    >
      <div
        className="border rounded bg-light h-100"
        style={{ width: width ?? "300px" }}
      >
        <div
          {...attributes} //uncomment for column swap
          {...listeners}
          style={{ backgroundColor: "#E6EDFF" }}
          className="w-100"
        >
          {label}
        </div>
        <div
          style={{ height: columnHieght }}
          className="overflow-scroll d-flex flex-column align-items-center"
          id="kanban-scrollable-div"
        >
        <InfiniteScroll
            scrollableTarget={"kanban-scrollable-div"}
            dataLength={tasks?.length ?? 0}
            next={() => {
              setIsLoading(true);
              columnData?.nextPage(columnData,page+1);
              setPage(page + 1);
              setIsLoading(false);
            }}
            hasMore={tasks?.length<999}
            endMessage={
              isLoading ? (
                <p style={{ textAlign: "center", color: "gray" }}>
                  Loading Applications
              </p>
              ) : (
                tasks?.length ? (
                  <p style={{ textAlign: "center", color: "gray" }}>
                    Loaded all the Applications 👍
                  </p>
                ) : (
                  <p style={{ textAlign: "center", color: "gray" }}>
                    No Applications Found 😔
                  </p>
                )
              )
            }
        >
            {/** task box */}
            {/* <SortableContext items={tasksIds}> */}
              {tasks?.map((task, idx) => (
                <TaskCard key={idx} taskData={task} {...columnData} />
              ))}
            {/* </SortableContext> */}
        </InfiniteScroll>
        </div>
      </div>
    </div>
  );
}

function TaskCard({ taskData, render, getId, columnName }) {
  const [mouseIsOver, setMouseIsOver] = useState(false);

  const {
    setNodeRef,
    attributes,
    listeners,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: getId(taskData), //defining taskData unique Id's
    data: {
      type: "Task",
      taskData,
      render,
      getId,
      columnName
    },
  });

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };

  if (isDragging) {
    return (
      <div
        ref={setNodeRef}
        className={`my-2 pointer rounded`}
        style={{
          ...style,
          boxSizing: "content-box",
          width: "270px",//box is not dynamic
          backgroundColor: "#E6EDFF",
          border:"2px solid #333333",
        }}
      >
        <div
        className="rounded"
          style={{ width: "100%", height: 100}}
        ></div>
      </div>
    );
  }

  return (
    <div
      ref={setNodeRef}
      className={`my-2 pointer`}
      {...attributes}
      {...listeners}
      style={{
        ...style,
        boxSizing: "content-box",
        width: "270px",
        backgroundColor: "#F9F8FF",
      }}
      onMouseEnter={() => {
        setMouseIsOver(true);
      }}
      onMouseLeave={() => {
        setMouseIsOver(false);
      }}
    >
      <div style={{ width: "100%"}}>{render(taskData)}</div>
    </div>
  );
}
export default KanbanBoard;
