import { Grid, Skeleton } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

import {
  DirectionsRenderer,
  GoogleMap,
  LoadScript,
} from "@react-google-maps/api";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getGeocode, getLatLng } from "use-places-autocomplete";
import { getAllLocations } from "../../api";

import DirectionSummery from "./DirectionSummery";
import {
  SearchComponent,
  CustomAutoComplete,
  PinPointsComponent,
  MarkersComponent,
  formateLocations,
} from "./MapView";

function NewMapView({ open, handleClose }) {
  const [loading, setLoading] = useState(false);
  const [locations, setLocations] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [formatedData, setFormatedData] = useState([]);
  const [stateFilter, setStateFilter] = useState(null);
  const [cityFilter, setCityFilter] = useState(null);
  const [pinPointsFilter, setPinPointsFilter] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [route, setRoute] = useState(null);
  const [map, setMap] = useState(null);
  const [directions, setDirections] = useState({
    A: { lat: null, lng: null },
    B: { lat: null, lng: null },
  });
  const [mapCenter, setMapCenter] = useState({ lat: 21.0, lng: 78.0 });
  const [gpsLoading, setGpsLoading] = useState(false);
  const [directionSummery, setDirectionSummery] = useState(null);
  const [zoom, setZoom] = useState(5);

  function handleSearch(locationData) {
    try {
      map?.setZoom(30);
      map?.panTo(locationData?.location);
      setMapCenter(locationData?.location);
    } catch (error) {
      toast.error(
        error?.response?.data?.message || error?.message || "failed to search!"
      );
    } finally {
    }
  }

  async function handleLocationFocus(address, zoom) {
    try {
      if (map) {
        const results = await getGeocode({ address });
        const location = await getLatLng(results[0]);
        const { lat, lng } = location;
        map?.setZoom(zoom);
        map?.panTo({ lat, lng });
        setMapCenter({ lat, lng });
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          error?.message ||
          "failed to focus on location"
      );
    }
  }

  const calculateDirections = async ({ from, to }) => {
    try {
      if (!from || !to) {
        toast.error("Missing origin or destination for directions");
        return;
      }
      const directionsService = new window.google.maps.DirectionsService();
      const travelMode = window.google.maps.TravelMode.DRIVING;
      const directionsRequest = {
        origin: new window.google.maps.LatLng(from.lat, from.lng),
        destination: new window.google.maps.LatLng(to.lat, to.lng),
        travelMode, // Adjust travel mode as needed (driving, walking, bicycling)
      };
      const response = await directionsService.route(directionsRequest);
      if (response?.routes) {
        setRoute(response);
        setDirectionSummery({
          distance: response?.routes?.[0]?.legs?.[0]?.distance?.text,
          duration: response?.routes?.[0]?.legs?.[0]?.duration?.text,
        });
      }
    } catch (error) {
      toast?.error(error?.message);
    }
  };

  async function fetchGPSData() {
    try {
      setGpsLoading(true);
      const { data } = await getAllLocations("filter=gps");
      let result = {
        gps_locations: data?.data || [],
      };

      let { locations = [], search_options = [] } = formateLocations(
        result || {}
      );

      setFormatedData((prev) => {
        if (!prev?.locations) {
          prev.locations = [];
        }
        if (!prev?.search_options) {
          prev.search_options = [];
        }
        prev.locations = prev.locations.concat(locations);
        prev.search_options = prev.search_options.concat(search_options);
        return {
          ...prev,
        };
      });
      setLocations((prev) => [...(prev || []), ...(locations || [])]);
      setSearchData((prev) => [...(prev || []), ...(search_options || [])]);
    } catch (error) {
      toast.error(
        error?.response?.data?.message || error?.message || "failed to search!"
      );
    } finally {
      setGpsLoading(false);
    }
  }

  async function fetchAllLocations() {
    try {
      setLoading(true);
      let result = {
        agent_locations: [],
        dealer_locations: [],
        customer_locations: [],
        gps_locations: [],
      };
      let params = ["customer", "dealer", "agent"];
      const customer_locations = JSON.parse(
        sessionStorage.getItem("customer_locations")
      );
      if (customer_locations) {
        result = {
          ...result,
          customer_locations,
        };
        params = params.filter((itm) => itm !== "customer");
      }
      const dealer_locations = JSON.parse(
        sessionStorage.getItem("dealer_locations")
      );
      if (dealer_locations) {
        result = {
          ...result,
          dealer_locations,
        };
        params = params.filter((itm) => itm !== "dealer");
      }

      fetchGPSData();
      const promises = [];
      for (let filter of params) {
        promises.push(getAllLocations(`filter=${filter}`));
      }

      const response = await Promise.all(promises);

      for (let i = 0; i < response.length; i++) {
        const data = response?.[i]?.data?.data;
        result[`${params[i]}_locations`] = data || [];
        if (["dealer", "customer"].includes(params[i])) {
          sessionStorage.setItem(
            `${params[i]}_locations`,
            JSON.stringify(data)
          );
        }
      }
      const formated_data = formateLocations(result || {});
      setFormatedData(formated_data);
      setLocations(formated_data?.locations || []);
      setFilterData(formated_data?.filters || []);
      setSearchData(formated_data?.search_options);
    } catch (error) {
      toast.error(
        error?.response?.data?.message || error?.message || "failed to search!"
      );
    } finally {
      setLoading(false);
    }
  }

  function handleFullScreenDistance() {
    if (route && directionSummery && document.fullscreenElement) {
      document.fullscreenElement.id = "full-screen-element";
      const div1 = document.createElement("div");
      div1.className =
        "position-absolute bottom-0 left-0 bg-white text-dark border rounded m-2 px-2 full-screen-child";
      div1.style.zIndex = 1000003;
      div1.style.width = "40%";
      const div2 = document.createElement("div");
      div2.className = "d-flex flex-column fs-6 fw-semibold m-2";
      const div3 = document.createElement("div");
      div3.className =
        "d-flex flex-row align-items-center justify-content-between";
      const div4 = document.createElement("div");
      div4.className = "d-flex flex-column align-items-start";

      const span1 = document.createElement("span");
      span1.className = "fs-6 fw-normal text-capitalize";
      span1.innerText = `Distance: ${directionSummery?.distance}  |  Duration: ${directionSummery?.duration}  |  Mode : Driving`;
      const span2 = document.createElement("span");
      span2.className = "py-1";
      span2.innerText = `Location A: ${
        directions?.A?.name +
        " ( " +
        (directions?.A?.user_type || "agent") +
        " ) "
      }`;
      const span3 = document.createElement("span");
      span3.innerText = `Location B: ${
        directions?.B?.name +
        " ( " +
        (directions?.B?.user_type || "agent") +
        " ) "
      }`;

      const button = document.createElement("button");
      button.className =
        "mt-2 rounded-pill btn btn-primary bg-primary shadow-lg fw-semibold";
      button.innerText = "Reset";
      button.addEventListener("click", (e) => {
        let child = document.getElementsByClassName("full-screen-child");
        if (child) {
          Array.from(child).map((itm) => itm.remove());
        }
        setRoute(null);
        setDirections({ A: null, B: null });
        setDirectionSummery(null);
      });

      div4.appendChild(span2);
      div4.appendChild(span3);
      div3.appendChild(div4);
      div3.appendChild(button);
      div2.appendChild(div3);
      div2.appendChild(span1);
      div1.appendChild(div2);
      document.fullscreenElement.appendChild(div1);
    }
  }

  const handleFullScreenChange = () => {
    if (!document.fullscreenElement) {
      let child = document.getElementsByClassName("full-screen-child");
      if (child) {
        Array.from(child).map((itm) => itm.remove());
      }
      setRoute(null);
      setDirections({ A: null, B: null });
      setDirectionSummery(null);
    } else {
      handleFullScreenDistance();
    }
  };

  function handleZoomChange() {
    if (map) setZoom(map.getZoom());
  }

  useEffect(() => {
    handleFullScreenDistance();
  }, [directionSummery]);

  useEffect(() => {
    if (pinPointsFilter?.length) {
      setLocations(
        formatedData?.locations?.filter((itm) => {
          return pinPointsFilter?.includes(itm?.user_type);
        })
      );
    } else {
      setLocations([...(formatedData?.locations || [])]);
    }
  }, [pinPointsFilter]);

  useEffect(() => {
    if (
      map &&
      directions?.A?.lat &&
      directions?.A?.lng &&
      directions?.B?.lat &&
      directions?.B?.lng
    ) {
      calculateDirections({ from: directions?.A, to: directions?.B });
    } else {
      setRoute(null);
    }
  }, [directions]);

  useEffect(() => {
    if (open) {
      document.addEventListener("fullscreenchange", handleFullScreenChange);
      document.addEventListener(
        "webkitfullscreenchange",
        handleFullScreenChange
      );

      setMapCenter({ lat: 21.0, lng: 78.0 });
      fetchAllLocations();

      return () => {
        document.removeEventListener(
          "fullscreenchange",
          handleFullScreenChange
        );
        document.removeEventListener(
          "webkitfullscreenchange",
          handleFullScreenChange
        );
      };
    }
  }, [open]);

  const getContainerHeight = () => {
    const selectedOptionsCount = [
      stateFilter,
      cityFilter,
      pinPointsFilter,
    ].filter(Boolean).length;
    return selectedOptionsCount > 2 ? `${selectedOptionsCount * 40}px` : "auto";
  };

  let { REACT_APP_MAP_KEY } = process.env;

  return (
    <div className="dialog-container">
      <div
        className="input-container mx-2"
        style={{ height: getContainerHeight() }}
      >
        <div className="input-wrapper-small-input">
          <SearchComponent locations={searchData} onSubmit={handleSearch} />
        </div>
        <div className="input-wrapper-small-input">
          <CustomAutoComplete
            label="State"
            value={stateFilter}
            onChange={(event, newValue) => {
              newValue && handleLocationFocus(newValue, 7);
              setStateFilter(newValue);
              setCityFilter(null);
            }}
            objKey={"state"}
            dropdownOptions={filterData}
          />
        </div>
        <div className="input-wrapper-small-input">
          <CustomAutoComplete
            label="City"
            value={cityFilter}
            onChange={(event, newValue) => {
              setCityFilter(newValue);
              handleLocationFocus(newValue + ", " + stateFilter, 13);
            }}
            objKey={"city"}
            dropdownOptions={filterData?.filter((itm) =>
              stateFilter ? stateFilter === itm?.state : false
            )}
          />
        </div>
        <div className="input-wrapper-small-input">
          <PinPointsComponent
            onChange={(value) => {
              setPinPointsFilter(value);
            }}
          />
        </div>
      </div>
      <div className="dialog-content">
        <Grid container spacing={2} className="h-100">
          <Grid item xs={12} sm={12} className="h-100">
            <div className="border h-100 d-flex flex-column align-items-start position-relative">
              <LoadScript
                googleMapsApiKey={REACT_APP_MAP_KEY}
                libraries={["routes", "places"]}
                loadingElement={
                  <Skeleton
                    variant="rectangular"
                    width={"100%"}
                    height={"100%"}
                  />
                }
              >
                <GoogleMap
                  mapContainerStyle={{ width: "100%", height: "100%" }}
                  center={mapCenter}
                  zoom={zoom}
                  onZoomChanged={handleZoomChange}
                  onLoad={(map) => setMap(map)}
                  mapTypeId="hybrid"
                  streetView={true}
                >
                  {!route && zoom >= 3 && (
                    <MarkersComponent
                      locations={locations}
                      setDirections={setDirections}
                      directions={directions}
                      map={map}
                    />
                  )}
                  {route && (
                    <DirectionsRenderer
                      options={{
                        directions: route,
                        suppressMarkers: true,
                      }}
                    />
                  )}
                  {route && directions?.A && directions?.B && (
                    <MarkersComponent
                      locations={Object.keys(directions)?.map((key) => ({
                        ...directions[key],
                        label: key,
                      }))}
                      setDirections={setDirections}
                      directions={directions}
                      map={map}
                    />
                  )}
                  {route && directionSummery && (
                    <DirectionSummery
                      handleReset={() => {
                        setDirectionSummery(null);
                        setRoute(null);
                        setDirections({ A: null, B: null });
                      }}
                      directionSummery={directionSummery}
                      directionData={directions}
                      width={"50%"}
                    />
                  )}
                </GoogleMap>
              </LoadScript>
              {loading && (
                <div className="loading-overlay">
                  <svg width={0} height={0}>
                    <defs>
                      <linearGradient
                        id="my_gradient"
                        x1="0%"
                        y1="0%"
                        x2="0%"
                        y2="100%"
                      >
                        <stop offset="0%" stopColor="#e01cd5" />
                        <stop offset="100%" stopColor="#1CB5E0" />
                      </linearGradient>
                    </defs>
                  </svg>
                  <CircularProgress
                    size={60}
                    thickness={5}
                    sx={{ "svg circle": { stroke: "url(#my_gradient)" } }}
                  />
                </div>
              )}
              {gpsLoading && (
                <div className="gps-loading-overlay">
                  <svg width={0} height={0}>
                    <defs>
                      <linearGradient
                        id="my_gradient"
                        x1="0%"
                        y1="0%"
                        x2="0%"
                        y2="100%"
                      >
                        <stop offset="0%" stopColor="#e01cd5" />
                        <stop offset="100%" stopColor="#1CB5E0" />
                      </linearGradient>
                    </defs>
                  </svg>
                  <CircularProgress
                    size={40}
                    thickness={4}
                    sx={{ "svg circle": { stroke: "url(#my_gradient)" } }}
                  />
                  <span className="fs-5 fw-bold text-white ms-4">
                    Loading GPS Locations...
                  </span>
                </div>
              )}
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default NewMapView;
