export const environment = process.env.REACT_APP_ENVIRONMENT || "development";

export const URL = {
  local:"http://localhost:4001/api/v1",
  development: "https://api.lms.embifi.in/api/v1",
  local: "http://localhost:4001/api/v1",
  production: "https://prod.api.lms.embifi.in/api/v1",
};

export const APP_VERSION = "1.8.0";
