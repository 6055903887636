import React, { useState, useEffect, useCallback, useContext } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  TextField,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Pagination,
  PaginationItem,
  buttonBaseClasses,
} from '@mui/material';
import SearchIcon from "@mui/icons-material/Search";
import EditNoteIcon from '@mui/icons-material/EditNote';
import CustomTableV2 from './CustomTable/CustomTableV2';
import CibilDashboardFilter from './Sort/CibilDashboardFilter.js';
import { getCibilData, cibilDataChanges, updateAgentData } from '../api';
import { toast } from "react-toastify";
import './CibilDashboardPopup.css'
import { UserContext } from '../Context/UserContext';
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const CibilDashboardPopup = ({ open, openCibilPopup, setOpenCibilPopup, setIsEdit, isEdit }) => {
  const [data, setData] = useState([]);
  const [initialData, setInitialData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [noDataFound, setNoDataFound] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(50);
  const [count, setCount] = useState(0);
  const [search,setSearch] = useState("");
  const [willSearch, setWillSearch] = useState(false);
  const [rowToSave, setRowToSave] = useState(null);
  const [currentApplicationToUpdate, setCurrentApplicationToUpdate ] = useState(null);
  const { user, setUser } = useContext(UserContext);
  const [editApplicationId, setEditApplicationId] = useState(null);
  
  // const [customerStatus, setCustomerStatus] = useState("");
  // const [coCustomerStatus, setCoCustomerStatus] = useState("");
  // const [date, setDate] = useState(null);  // Assuming date is a Date object or null
  const [openFilter, setOpenFilter] = useState(false);
  const [filterApplied, setFilterApplied] = useState(false);
  const [filterState, setFilterState] = useState({
    customerStatus: "",
    coCustomerStatus: "",
    date: null,
  });
  const [canClearFilter, setCanClearFilter] = useState(false);
  
  const columns = [
    {
      label: "Request ID",
      render: (row)=>data[row]?.request_id,
      width: '100px',
    },
    {
      label: "Request Date",
      render: (row) => {
        const timestamp = data[row]?.request_date;
    
        if (!timestamp) return "-"; // Return a placeholder if no date is present
    
        const date = new Date(timestamp);
        return date.toLocaleDateString('en-GB'); // Format as DD/MM/YYYY
      },
      width: '120px',
    },
    
    {
      label: "Application ID",
      render: (row)=>data[row]?.application_id,
      width: '190px',
    },
    {
      label: "Customer Name",
      render: (row)=>data[row]?.customer_name,
      width: '180px',
    },
   
    {
      label: "Customer Status",
      render: (row) => {
        const isEditable = editApplicationId === data[row]?.application_id;
        if(user?.departments?.includes("CREDIT_CHECK")){
          return (
            <FormControl fullWidth>
              <Select
                id="customer_status"
                value={
                  data[row]?.customer_status === true
                      ? "Approved"
                      : data[row]?.customer_status === false
                        ? "Rejected"
                        : "Awaiting"
                }
                onChange={(event) => handleStatusChange(data[row].application_id, 'customer_status', event.target.value, row)}
                disabled={!isEditable || !isEdit}
              >
                <MenuItem value="Approved">Approved</MenuItem>
                <MenuItem value="Rejected">Rejected</MenuItem>
                <MenuItem value="Awaiting">Awaiting</MenuItem>
              </Select>
            </FormControl>
          )
        }
        else{
          return data[row]?.customer_status == true
                  ? "Approved"
                  : data[row]?.customer_status == false
                    ? "Rejected"
                    : "Awaiting"
        }
    },
      width: '130px',
    },
    {
      label: "Co-Customer Status",
    render: (row) => {
      const isEditable = editApplicationId === data[row]?.application_id;
      if(user?.departments?.includes("CREDIT_CHECK")){
        return (
          <FormControl fullWidth>
            <Select
              id="co_customer_status"
              value={
                data[row]?.co_customer_status === true
                    ? "Approved"
                    : data[row]?.co_customer_status === false
                      ? "Rejected"
                      : "Awaiting"
              }
              onChange={(event) => handleStatusChange(data[row].application_id, 'co_customer_status', event.target.value, row)}
              disabled={!isEditable || !isEdit}
            >
              <MenuItem value="Approved">Approved</MenuItem>
              <MenuItem value="Rejected">Rejected</MenuItem>
              <MenuItem value="Awaiting">Awaiting</MenuItem>
            </Select>
          </FormControl>
        )
      }
      else{
        return data[row]?.co_customer_status === true
        ? "Approved"
        : data[row]?.co_customer_status === false
          ? "Rejected"
          : "Awaiting"
      }
    },
      width: '130px',
    },
    {
      label: "Remark",
      render: (row) => {
        const isEditable = editApplicationId === data[row]?.application_id;
        if(user?.departments?.includes("CREDIT_CHECK")){
          return (
            <TextField
            value={data[row]?.remark == "" ? "" : data[row]?.remark}
            disabled={!isEditable || !isEdit}
              id='remark'
              onChange={(e) => handleStatusChange(data[row].application_id, "remark", e.target.value, row)}
              variant="outlined"
              fullWidth
            />
          )
        }
        else{
          return data[row]?.remark
        }
    },
      width: '260px',
    },
    user?.departments?.includes("CREDIT_CHECK") && {
      label: '',
      render: (row) => (
        <Button
          variant="contained"
          color="primary"
          disabled={rowToSave?.application_id == data[row]?.application_id ? false : true}
          onClick={handleSave} 
        >
          Save
        </Button>
      ),
      width: '20px',
    },
    {
      label: "",
      render: (row) => {
        if (user?.departments?.includes("CREDIT_CHECK")) {
          return (
            <EditNoteIcon
              onClick={() => handleEditClick(data[row].application_id)}
              sx={{
                cursor: 'pointer',
                transition: 'all 150ms ease',
                '&:hover': {
                  backgroundColor: 'lightgray',
                },
                borderRadius: '4px',
                padding: '4px',
                fontSize: '2rem'
              }}
            />
          );
        }
      },
      width: '5px',
    }
  ].filter(Boolean);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleEditClick = (application_id) => {
    setRowToSave(null);
    if (editApplicationId === application_id) {
     setIsEdit((prev) => !prev);
    } else {
     setEditApplicationId(application_id);
      setIsEdit(true);
      if (currentApplicationToUpdate && currentApplicationToUpdate !== application_id) {
        const index = data.findIndex(item => item.application_id === currentApplicationToUpdate);
        const updatedData = [...data];
        updatedData[index] = initialData[index];
        setData(updatedData);
      }
      setCurrentApplicationToUpdate(application_id);
    }
  };

  const serializeFilters = (filters) => {
    const serializedFilters = {};

    Object.keys(filters).forEach(key => {
      const value = filters[key];
      if (value !== null && value !== undefined && value !== "") {
        // Handle date filter (if date is an object with start and end)
        if (key === 'requestDate' && typeof value === 'object' && value.start && value.end) {
          serializedFilters[key] = {
            start: value.start,
            end: value.end
          };
        } else {
          serializedFilters[key] = value;
        }
      }
    });

    return serializedFilters;
  };
  

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true)
        const filterObject = serializeFilters(filterState); // Serialize to a JSON object
        const filterString = JSON.stringify(filterObject); // Stringify the object
        const response = await getCibilData(limit, page, search, filterString);
        const mappedData = response.data.data.data.map(item => ({
          request_id: item?.request_id || "", // Ensure each item has a unique 'id'
          application_id: item?.application_id,
          customer_name: item?.customer_name,
          customer_status: item?.customer_status,
          co_customer_status: item?.co_customer_status,
          remark: item?.remark,
          request_date: item?.request_date
        }));
        
        setData(mappedData);
        setInitialData(mappedData);
        setCount(Math.ceil(response.data.data.count));
        setNoDataFound(mappedData.length === 0);
        setWillSearch(false);
      } catch (error) {
        setNoDataFound(true);
      }
      finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [page, 
    willSearch,
  openCibilPopup, filterState ]);
 
  const handleSearchInputChange = (event) => {
    setSearch(event.target.value);
  };

  
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      setWillSearch((prev)=>!prev);
      // handleSearch();
    }
  };
  
  const checkCustomerStatus = (param) => {
    if (param === "Approved") {
      return true;
    } else if (param === "Rejected") {
      return false;
    } else {
      return param === "" ? "" : null;
    }
  };
  const checkCoCustomerStatus = (param) => {
    if (param === "Approved") {
      return true;
    } else if (param === "Rejected") {
      return false;
    } else {
      return param === "" ? "" : null;
    }
  };



  const checkRemark = (param) => {
    if (param === "") {
      return "";
    } else {
      return param;
    }
  }


  const handleStatusChange = (application_id, fieldName, value, row) => {
    const tempData = [...data];
    if(currentApplicationToUpdate != application_id){
      const index = tempData.findIndex(item => item.application_id === currentApplicationToUpdate);
      tempData[index] = initialData[index];
    }

    let updatedField;
    switch (fieldName) {
        case 'remark':
            updatedField = checkRemark(value);
            break;
        case 'customer_status':
            updatedField = checkCustomerStatus(value);
            break;
        case 'co_customer_status':
            updatedField = checkCoCustomerStatus(value);
            break;
        default:
            updatedField = value;
    }

    tempData[row] = {
        ...tempData[row],
        application_id,
        [fieldName]: updatedField
    };

    setRowToSave(tempData[row]);

    setCurrentApplicationToUpdate(application_id);

    setData(tempData);
};


  const handleClose = () => {
    
    setOpenCibilPopup(false);
    setSearch("");
    setRowToSave(null);
  }

  const handleSave = async () => {
    if (!rowToSave) {
      return; // No row to save
    }
  
    try {
      const response = await cibilDataChanges(rowToSave);
      if (response?.data?.status) {
        setData((prev) =>
          prev.map((each) =>
            each?.application_id === rowToSave?.application_id
              ? { ...each, ...rowToSave }
              : each
          )
        );
        // setUpdateData({
        //   application_id: "",
        //   remark: "",
        //   customer_status: "",
        //   co_customer_status: "",
        // });
        setIsEdit(false);
        setRowToSave(null); // Reset rowToSave after save
        toast.success("Data successfully saved!!!");
      }
    } catch (error) {
      toast.error(error?.message || "Something went wrong");
    }
  };

  const handleFilterClose = () => {
    setOpenFilter(false);
  };

  const handleFilterApply = (filters) => {
    setCanClearFilter(false);
    setFilterState(filters);
    setFilterApplied(true);
    // setWillSearch((prev) => !prev);
  };

  const handleFilterReset = () => {
    setFilterState({
      customerStatus: "",
      coCustomerStatus: "",
      date: null,
    });
    setCanClearFilter(true);
    setFilterApplied(false);
    setWillSearch(true);
    // getCibilData(1,50)
  };
  
  

  return (
    <>
    <Dialog
    open={open}
    onClose={handleClose}
    maxWidth="md"
    fullWidth
    className='dialog-paper'
    sx={{
      '& .MuiDialog-paper': {
        width: '1400px',
        height: '930px',
        maxWidth: 'none', 
      },
    }}
  >
    <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center',}}>
      <span>CIBIL Dashboard</span>
      {/* <div style={{position: 'absolute', left: '190px'}}>
      <Button
        className="mx-3"
        sx={{ color: "gray" }}
        onClick={() => {setOpenFilter(true);}}
        >
        <FilterAltIcon fontSize="small" /> Filters
      </Button>
      <button>

      </button>
      </div> */}
{!filterApplied ? (
  <div style={{position: 'absolute', left: '190px'}}>
                <Button
                  className="mx-3"
                  sx={{ color: "gray" }}
                  onClick={() => {
                    setOpenFilter(true);
                  }}
                >
                  <FilterAltIcon fontSize="small" /> Filters
                </Button>
                </div>
              
            ) : (
              <div style={{position: 'absolute', left: '190px'}}>
                <Button
                  onClick={() => {
                    setFilterApplied(true);
                    setOpenFilter(true);
                  }}
                >
                  <span className="zoom-animation d-flex flex-row align-items-center">
                    <FilterAltIcon fontSize="small" onClick={()=> {
                      setFilterApplied(false);
                    }}/> Filters
                  </span>
                </Button>
                <span
                  className="pointer mx-3"
                  onClick={handleFilterReset}
                >
                  <HighlightOffIcon style={{ fontSize: 20 }}/>
                </span>
              </div>
            )}

      <div style={{ position: 'relative' }}>
        <TextField
          className="search-bar-table"
          id="input-with-icon-textfield"
          placeholder="Search"
          variant="standard"
          value={search}
          onChange={handleSearchInputChange}
          onKeyDown={handleKeyDown}
          InputProps={{
            startAdornment: <SearchIcon sx={{ color: "gray" }} />,
            disableUnderline: true,
            style: { color: "black", fontWeight: 400, height: 25, right: 8 },
          }}
        />
        
      </div>
    </DialogTitle>

    <DialogContent>
      <div style={{ height: '300px',  }}>
        {isLoading ? (
          <div style={{ textAlign: 'center', padding: '20px' }}>
            <CircularProgress />
          </div>
          
        ) : (
          <div className='table-container'>
{noDataFound ? (
                <div style={{ textAlign: 'center', padding: '20px' }}>No data found</div>
              ) : (
                <CustomTableV2
                  tableData={data}
                  columns={columns}
                  tableHeight="69vh"
                  isLoading={isLoading}
                  pageLoading={true}
                  blueTheme={true}
                  defaultLimit={limit}
                  allowAll={true}
                  page={page}
                  limit={limit}
                  isPaginated={true}
                  totalCount={count}
                  onPageChange={handlePageChange}
                />
              )}
          </div>
        )}
      </div>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose} color="primary">Close</Button>
    </DialogActions>
    {/* <div className='pagination-component'>
    <Pagination
          count={pageCount}
          page={page}
          variant="outlined"
          color="primary"
          onChange={handlePageChange}
        />
    </div> */}
    
  </Dialog>
  <CibilDashboardFilter
    open={openFilter}
    handleClose={handleFilterClose}
    onApply={handleFilterApply}
    setFilter={setFilterApplied}
    initialFilters={filterState}
    clearFilter={canClearFilter}
    setClearFilter={setCanClearFilter}
    />
  </>
  );
};

export default CibilDashboardPopup;