export const documentsList = [
  // customer start
  "applicant_pan",

  // 'guarantor_pan',
  //'Partner pan',
  // 'director_pan',
  // 'Applicant Aadhaar Front',
  "bank_statement",
  "aadhaar_front",
  "aadhaar_back",
  "residence_electricity_bill",
  "business_electricity_bill",
  "other_ownership_proof",
  "applicant_photo",
  "applicant_voter_id",
  "coapplicant_voter_id",
  "Coapplicant_dl",
  "coapplicant_passport",
  "coapplicant_photo",
  "coapplicant_aadhaar_front",
  "coapplicant Aadhaar Back",
  "coapplicant_pan",

  // 'guarantor_voter_id',
  //'Partner Voter ID',
  // 'director_voter_id',
  "applicant_dl",

  // 'guarantor_dl',
  //'Partner DL',
  // 'director_dl',
  "applicant_passport",

  // 'guarantor_passport',
  //'Partner Passport',
  // 'director_passport',
  "ownership_proof",

  // 'guarantor_photo',
  // 'Partner Photo',
  // 'director_photo',
  // ----customer end

  // 'Applicant Aadhaar Back',
  //
  // 'guarantor_aadhaar_front',
  // 'guarantor_aadhaar_back',
  //'Partner Aadhaar Front',
  //'Partner Aadhaar Back',
  // 'director_aadhaar_front',
  // 'director_aadhaar_back',

  // business details
  "gst_certificate",
  "fssai_certificate",
  "shop_and_establishment_certificate",
  "msme_certificate",
  // 'bye_laws',
  // 'moa',
  // 'aoa',
  // business details end

  // loan details start
  "erick_delivery_photo",
  "loan_agreement",
  "trc",
  "insurance",
  "form_20",
  "form_21",
  "form_22",
  "cheques",
  // loan detais end

  // bank details
  "invoice_or_quotation",
  "crif_report",
  "erick_delivery_photo",
  "stamp_paper",
  "delivery_order",
  "loan_agreement",
  "sanction_letter",
  "hypothecation_agreement",
  // bank details end
];

export const documentsV2List = {
  aadhaar_front: {
    category: "customer",
    name: "aadhaar_front",
  },
  aadhaar_back: {
    category: "customer",
    name: "aadhaar_back",
  },
  applicant_pan: {
    category: "customer",
    name: "applicant_pan",
  },
  driving_license_1: {
    category: "customer",
    name: "driving_license",
  },
  customer_photo_1: {
    category: "customer",
    name: "customer_photo_1",
  },
  other_ownership_proof_1: {
    category: "customer",
    name: "other_ownership_proof_1",
  },
  co_customer_pan: {
    category: "co_customer",
    name: "co_customer_pan",
  },
  co_customer_aadhaar_front: {
    category: "co_customer",
    name: "co_customer_aadhaar_front",
  },
  co_customer_aadhaar_back: {
    category: "co_customer",
    name: "co_customer_aadhaar_back",
  },
  co_customer_ownership_proof_1: {
    category: "co_customer",
    name: "co_customer_ownership_proof",
  },
  co_customer_photo_1: {
    category: "co_customer",
    name: "co_customer_photo",
  },
  customer_house_inside: {
    category: "field_investigation",
    name: "customer_house_inside",
  },
  customer_house_outside: {
    category: "field_investigation",
    name: "customer_house_outside",
  },
  customer_photo_with_electricity_meter: {
    category: "field_investigation",
    name: "customer_photo_with_electricity_meter",
  },
  fi_video: {
    category: "field_investigation",
    name: "fi_video",
  },
  loan_agreement_1: {
    name: "loan_agreement_1",
    category: "pre_disbursement",
  },
  stamp_paper_1: {
    name: "stamp_paper_1",
    category: "pre_disbursement",
  },
  dual_form_1: {
    name: "dual_form_1",
    category: "pre_disbursement",
  },
  sales_invoice_1: {
    name: "sales_invoice_1",
    category: "pre_disbursement",
  },
  quotation_1: {
    name: "quotation_1",
    category: "pre_disbursement",
  },
  // erick_delivery_photo_1: {
  //   name: "erick_delivery_photo_1",
  //   category: "pre_disbursement",
  // },
  chassis_number_1: {
    name: "chassis_number_1",
    category: "pre_disbursement",
  },
  battery_warranty_1: {
    name: "battery_warranty_1",
    category: "pre_disbursement",
  },
  insurance_1: {
    name: "insurance_1",
    category: "pre_disbursement",
  },
  form_20_1: {
    name: "form_20_1",
    category: "pre_disbursement",
  },
  form_21_1: {
    name: "form_21_1",
    category: "pre_disbursement",
  },
  form_22_1: {
    name: "form_22_1",
    category: "pre_disbursement",
  },
  form_26_35_1: {
    name: "form_26_35_1",
    category: "pre_disbursement",
  },
  trc_1: {
    name: "trc_1",
    category: "pre_disbursement",
  },
  cheques_1: {
    name: "cheques_1",
    category: "pre_disbursement",
  },
  cheques_2: {
    name: "cheques_2",
    category: "pre_disbursement",
  },
  cheques_3: {
    name: "cheques_3",
    category: "pre_disbursement",
  },
  cheques_4: {
    name: "cheques_4",
    category: "pre_disbursement",
  },
  cheques_5: {
    name: "cheques_5",
    category: "pre_disbursement",
  },
  cheques_6: {
    name: "cheques_6",
    category: "pre_disbursement",
  },
  bsv_1: {
    name: "bsv_1",
    category: "pre_disbursement",
  },
  bank_passbook_1: {
    name: "bank_passbook_1",
    category: "pre_disbursement",
  },
  cibil_report_1: {
    name: "cibil_report_1",
    category: "pre_disbursement",
  },
  cibil_report_2: {
    name: "cibil_report_2",
    category: "pre_disbursement",
  },
  registration_certificate_1: {
    name: "registration_certificate_1",
    category: "post_disbursement",
  },
  courier_receipt_1: {
    name: "courier_receipt_1",
    category: "post_disbursement",
  },
  vehicle_front_photo_with_customer: {
    name: "vehicle_front_photo_with_customer",
    category: "pre_disbursement",
  },
  vehicle_side_photo_with_customer: {
    name: "vehicle_side_photo_with_customer",
    category: "pre_disbursement",
  },
  vehicle_back_photo_with_customer: {
    name: "vehicle_back_photo_with_customer",
    category: "pre_disbursement",
  },
  downpayment_receipt_1: {
    name: "downpayment_receipt_1",
    category: "pre_disbursement",
  },
  surrender_letter_1: {
    name: "surrender_letter_1",
    category: "pre_disbursement",
  },
};
