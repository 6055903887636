import React, { useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios'

const VoiceClone=()=>{   

    const voice_clone_api = "https://meet-master-wolf.ngrok-free.app/upload"

    const [audioFile, setAudioFile] = useState('');
    const [textInput, setTextInput] = useState('');
    const [processedAudio, setProcessedAudio] = useState(null);
    const audioRef = useRef(null);

    const navigate = useNavigate()
    const handleAudioFileChange = (e) => {
        setAudioFile(e.target.files[0]);
    };

    const handleTextInputChange = (e) => {
    setTextInput(e.target.value);
    };

    const refreshComponents = () =>{
        setTextInput('')
        audioRef.current.value = ''
        setProcessedAudio(null)
    }
    
    const handleProcessAudio = async () => {

    const formData = new FormData();
    formData.append('audio_file', audioFile);
    formData.append('text_input', textInput);

    try {
        const response = await axios.post(voice_clone_api, formData,{responseType:'blob'});

        if (response.status === 200) {
        setProcessedAudio(URL.createObjectURL(response.data));
        } else {
        console.error('Failed to process audio');
        }
    } catch (error) {
        console.error('Error:', error);
    }
    };

    return (
    <>
    <p className="go-back-btn-fixed mb-3" onClick={() => navigate("/")}>
    <ArrowBackIcon /> Go back Home
    </p>
    <div className='wrapper-center'>
    <div className="container ">
        <h1 className="mb-4">Voice Cloning Engine</h1>
        

        <div className="mb-3">
        <label htmlFor="audioFile" className="form-label">
            Select Audio File:
        </label>
        <input
            type="file"
            ref={audioRef}
            className="form-control"
            id="audioFile"
            accept="audio/*"
            onChange={handleAudioFileChange}
        />
        </div>

        <div className="mb-3">
        <label htmlFor="textInput" className="form-label">
            Text Input:
        </label>
        <textarea
            className="form-control"
            id="textInput"
            rows="3"
            value={textInput}
            onChange={handleTextInputChange}
        ></textarea>
        </div>

        <button className="btn btn-primary mb-3" onClick={handleProcessAudio}>
        Process Audio
        </button><br></br>
 

        {processedAudio && (
        <>
        <div className="mb-3">
            <audio controls>
            <source src={processedAudio} type="audio/wav" />
            Your browser does not support the audio element.
            </audio>
        </div>
        <br></br>
        <button className="btn btn-primary mb-3" onClick={refreshComponents}>
        Refresh page
        </button><br></br>
        <small>Need to refresh the page after each processing</small>
        </>
        )}
    </div>
    </div>
    </>
    );
};

export default VoiceClone