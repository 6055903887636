import React from "react";

const MyTableComponent = () => {
  return (
    <div className="ritz grid-container" dir="ltr">
      <style>
        {`
          .ritz .waffle a {
              color: inherit;
          }
      
          .ritz .waffle .s1 {
              border-bottom: 1px SOLID #000000;
              border-right: 1px SOLID #000000;
              background-color: #000000;
              text-align: center;
              font-weight: bold;
              color: #ffffff;
              font-family: 'Arial';
              font-size: 10pt;
              vertical-align: bottom;
              white-space: normal;
              overflow: hidden;
              word-wrap: break-word;
              direction: ltr;
              padding: 2px 3px 2px 3px;
          }
      
          .ritz .waffle .s4 {
              text-overflow: ellipsis;
              overflow: hidden;
              vertical-align: top;
              display: inline-block;
              height: fit-content;
              border-radius: 8px;
          }
      
          .ritz .waffle .s9 {
              border-bottom: 1px SOLID #000000;
              border-right: 1px SOLID #000000;
              background-color: #ffffff;
              text-align: center;
              font-weight: bold;
              color: #000000;
              font-family: 'Arial';
              font-size: 10pt;
              vertical-align: bottom;
              white-space: nowrap;
              direction: ltr;
              padding: 2px 3px 2px 3px;
          }
      
          .ritz .waffle .s10 {
              border-bottom: 1px SOLID #000000;
              border-right: 1px SOLID #000000;
              background-color: #ffffff;
              text-align: left;
              color: #000000;
              font-family: 'Arial';
              font-size: 10pt;
              vertical-align: bottom;
              white-space: nowrap;
              direction: ltr;
              padding: 2px 3px 2px 3px;
          }
      
          .ritz .waffle .s8 {
              border-bottom: 1px SOLID #000000;
              border-right: 1px SOLID #000000;
              background-color: #ffffff;
              text-align: center;
              color: #000000;
              font-family: 'Arial';
              font-size: 10pt;
              vertical-align: bottom;
              white-space: nowrap;
              direction: ltr;
              padding: 2px 3px 2px 3px;
          }
      
          .ritz .waffle .s6 {
              border-bottom: 1px SOLID #000000;
              border-right: 1px SOLID #000000;
              background-color: #ffd966;
              text-align: center;
              font-weight: bold;
              color: #000000;
              font-family: 'Arial';
              font-size: 10pt;
              vertical-align: bottom;
              white-space: nowrap;
              direction: ltr;
              padding: 2px 3px 2px 3px;
          }
      
          .ritz .waffle .s5 {
              border-bottom: 1px SOLID #000000;
              border-right: 1px SOLID #000000;
              background-color: #cccccc;
              text-align: center;
              font-weight: bold;
              color: #000000;
              font-family: 'Arial';
              font-size: 10pt;
              vertical-align: bottom;
              white-space: nowrap;
              direction: ltr;
              padding: 2px 3px 2px 3px;
          }
      
          .ritz .waffle .s7 {
              border-bottom: 1px SOLID #000000;
              border-right: 1px SOLID #000000;
              background-color: #cccccc;
              text-align: left;
              font-weight: bold;
              color: #000000;
              font-family: 'Arial';
              font-size: 10pt;
              vertical-align: bottom;
              white-space: nowrap;
              direction: ltr;
              padding: 2px 3px 2px 3px;
          }
      
          .ritz .waffle .s3 {
              border-bottom: 1px SOLID #000000;
              border-right: 1px SOLID #000000;
              background-color: #c9daf8;
              text-align: center;
              font-weight: bold;
              color: #000000;
              font-family: 'Arial';
              font-size: 10pt;
              vertical-align: bottom;
              white-space: nowrap;
              direction: ltr;
              padding: 2px 3px 2px 3px;
          }
      
          .ritz .waffle .s2 {
              border-bottom: 1px SOLID #000000;
              border-right: 1px SOLID #000000;
              background-color: #93c47d;
              text-align: center;
              font-weight: bold;
              color: #000000;
              font-family: 'Arial';
              font-size: 10pt;
              vertical-align: bottom;
              white-space: nowrap;
              direction: ltr;
              padding: 2px 3px 2px 3px;
          }
      
          .ritz .waffle .s0 {
              border-bottom: 1px SOLID #000000;
              border-right: 1px SOLID #000000;
              background-color: #4a86e8;
              text-align: center;
              font-weight: bold;
              color: #ffffff;
              font-family: 'Arial';
              font-size: 10pt;
              vertical-align: bottom;
              white-space: nowrap;
              direction: ltr;
              padding: 2px 3px 2px 3px;
          }
        `}
      </style>
      <table className="waffle" cellSpacing="0" cellPadding="0">

        <tbody>
          <tr style={{ height: "20px" }}>
            <th
              id="0R0"
              style={{ height: "20px" }}
              className="row-headers-background"
            >
              <div
                className="row-header-wrapper"
                style={{ lineHeight: "20px" }}
              >
                
              </div>
            </th>
            <td className="s0">Select NBFC</td>
            <td className="s1" rowSpan="2">
              MoM Collection Dashboard
            </td>
            <td className="s2" colSpan="5">
              Repayment Month
            </td>
          </tr>
          <tr style={{ height: "20px" }}>
            <th
              id="0R1"
              style={{ height: "20px" }}
              className="row-headers-background"
            >
              <div
                className="row-header-wrapper"
                style={{ lineHeight: "20px" }}
              >
                
              </div>
            </th>
            <td className="s3">
              <span
                className="s4"
                style={{
                  backgroundColor: "#e8eaed",
                  color: "#000000",
                  width: "72.0px",
                  maxWidth: "72.0px",
                  marginLeft: "6.0px",
                  padding: "1.0px 5.0px 1.0px 5.0px",
                }}
              >
                All
              </span>
            </td>
            <td className="s5">Till September</td>
            <td className="s5">October</td>
            <td className="s5">November</td>
            <td className="s5">December</td>
            <td className="s5">Total</td>
          </tr>
          <tr style={{ height: "20px" }}>
            <th
              id="0R2"
              style={{ height: "20px" }}
              className="row-headers-background"
            >
              <div
                className="row-header-wrapper"
                style={{ lineHeight: "20px" }}
              >
                
              </div>
            </th>
            <td className="s6" rowSpan="4">
              Due Month
            </td>
            <td className="s7">Till September</td>
            <td className="s8">98.10%</td>
            <td className="s8">0.90%</td>
            <td className="s8">0.70%</td>
            <td className="s8">0.30%</td>
            <td className="s9">100.00%</td>
          </tr>
          <tr style={{ height: "20px" }}>
            <th
              id="0R3"
              style={{ height: "20px" }}
              className="row-headers-background"
            >
              <div
                className="row-header-wrapper"
                style={{ lineHeight: "20px" }}
              >
                
              </div>
            </th>
            <td className="s7">October</td>
            <td className="s10"></td>
            <td className="s8">96.90%</td>
            <td className="s8">2.50%</td>
            <td className="s8">0.20%</td>
            <td className="s9">99.60%</td>
          </tr>
          <tr style={{ height: "20px" }}>
            <th
              id="0R4"
              style={{ height: "20px" }}
              className="row-headers-background"
            >
              <div
                className="row-header-wrapper"
                style={{ lineHeight: "20px" }}
              >
                
              </div>
            </th>
            <td className="s7">November</td>
            <td className="s10"></td>
            <td className="s10"></td>
            <td className="s8">97.20%</td>
            <td className="s8">2.30%</td>
            <td className="s9">99.50%</td>
          </tr>
          <tr style={{ height: "20px" }}>
            <th
              id="0R5"
              style={{ height: "20px" }}
              className="row-headers-background"
            >
              <div
                className="row-header-wrapper"
                style={{ lineHeight: "20px" }}
              >
                
              </div>
            </th>
            <td className="s7">December</td>
            <td className="s10"></td>
            <td className="s10"></td>
            <td className="s10"></td>
            <td className="s8">72.50%</td>
            <td className="s9">72.50%</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default MyTableComponent;
