import { Grid } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../../Context/GlobalContext";
import ReactStoreIndicator from "react-score-indicator";
import { fetchRepaymentScore } from "../../../api";

const ViewProfile = (props) => {
    const { updateDetailState } = useContext(GlobalContext);
    const [updateDetails, setUpdateDetails] = updateDetailState;
    const [embifiCreditScore, setEmbifiCreditScore] = useState(0);
    const [repaymentScore, setRepaymentScore] = useState(0);
    const [scoreType, setScoreType] = useState({
        bgColor: "#34D399",
        type: "Excellent"
    });

    const fetchRepayScore = async (id) => {
        let payload = {customer_id: id};
        const {data} = await fetchRepaymentScore(payload);
        setRepaymentScore(data?.data?.value);
    }

    useEffect(() => {
        // console.log(updateDetails);
        const customerCrifScore = (updateDetails?.crif_score || 0);
        const coCustomerCrifScore = (updateDetails?.co_app_crif_score || 0);
        // console.log(customerCrifScore, coCustomerCrifScore)
        let embifiScore;
        if (updateDetails?.co_app_crif_score !== null && updateDetails?.co_app_crif_score != 0) {
            embifiScore = Math.round((((Number(updateDetails?.crif_score || 0) + Number(updateDetails?.co_app_crif_score || 0)) / 2) / 100) * 90);
        } else {
            embifiScore = Math.round(((Number(updateDetails?.crif_score || 0)) / 100) * 90);
        }
        // console.log(embifiScore)
        setEmbifiCreditScore(embifiScore)
        if (embifiScore == 0) {
            setScoreType({
                bgColor: "#337BCE",
                type: "New to credit"
            })
        } else if (embifiScore < 300 || null) {
            setScoreType({
                bgColor: "#F87171",
                type: "Poor"
            })
        } else if (embifiScore > 300 && embifiScore < 600) {
            setScoreType({
                bgColor: "#FCD34D",
                type: "Average"
            })
        } else {
            setScoreType({
                bgColor: "#34D399",
                type: "Excellent"
            })
        }

        fetchRepayScore(updateDetails?.customer_id)
    }, [updateDetails])

    return (
        <>
            <Grid container spacing={2} className="mb-4">
                <Grid item xs={12}>
                    <div style={{ display: "flex", flexDirection: "row" }} className="overview-card">
                        <Grid sx={{
                            padding: "15px"
                        }} xs={6}>
                            <ReactStoreIndicator
                                value={embifiCreditScore}
                                maxValue={900}
                                lineWidth={17}
                                lineGap={0}
                                fadedOpacity={20}
                                maxAngle={180}
                                rotation={90}
                                stepsColors={[
                                    "#d12000",
                                    "#ed8d00",
                                    "#f1bc00",
                                    "#84c42b",
                                    "#53b83a",
                                    "#3da940",
                                    "#3da940",
                                    "#3da940"
                                ]}
                            />
                        </Grid>
                        <Grid sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-around",
                            padding: "20px"
                        }} xs={6}>
                            <Grid sx={{
                                fontSize: "25px",
                                fontWeight: "400"
                            }}>BenchMark</Grid>
                            <Grid >
                                <span style={{
                                    backgroundColor: scoreType?.bgColor,
                                    padding: "15px 30px",
                                    color: "#fff",
                                    fontSize: "40px",
                                    borderRadius: "6px"
                                }}>
                                    {scoreType?.type}
                                </span>
                            </Grid>
                            <Grid>
                                <span>Remark : </span>
                                <span>{updateDetails?.credit_response}</span>
                            </Grid>
                        </Grid>

                    </div>
                </Grid>
                <div style={{ width: "100%", }} className="card-content">
                    <div style={{
                        display: "flex",
                        gap: "40px",
                        marginBottom: "40px"
                    }}>
                        <ListItemComp
                            value={updateDetails?.crif_score || 0}
                            maxValue={900}
                            text="CRIF Score"
                            color={"#6F32BE"}
                            fadeColor={"#CEBEFC"}
                            circleOutline="#A07DEF"
                        />
                        <ListItemComp
                            value={65}
                            maxValue={100}
                            text={"Deep User profiling "}
                            color={"#B258C2"}
                            fadeColor={"#F5BDFF"}
                            circleOutline="#F5BDFF"
                        />
                        <ListItemComp
                            value={Math.round(repaymentScore)}
                            maxValue={800}
                            text={"Repayment Score"}
                            color={"#573398"}
                            fadeColor={"#CEBEFC"}
                            circleOutline="#A07DEF"
                        />

                    </div>
                    <div style={{
                        display: "flex",
                        gap: "40px",
                    }} >
                        <ListItemComp
                            value={60}
                            maxValue={100}
                            text={"Dealer Score"}
                            color={"#337BCE"}
                            fadeColor={"#C6E4FE"}
                            circleOutline="#C6E4FE"
                        />
                        <ListItemComp
                            value={90}
                            maxValue={100}
                            text={"OEM Score"}
                            color={"#9155FD"}
                            fadeColor={"#CEBEFC"}
                            circleOutline="#CEBEFC"
                        />
                    </div>

                </div>
            </Grid>
        </>
    );
};

export default ViewProfile;

const ListItemComp = ({ fadeColor, color, value, circleOutline, text, maxValue }) => {
    return (
        <div style={{
            width: "30%",
            padding: "20px"
        }} className="overview-card">
            <p>{text}</p>
            <div style={{
                height: "10px",
                width: "95%",
                borderRadius: "10px",
                backgroundColor: fadeColor
            }}>
                <div style={{
                    height: "10px",
                    backgroundColor: color,
                    width: `${(value / maxValue) * 100}%`,
                    borderRadius: "10px",
                }}>
                    <div style={{
                        height: "20px",
                        width: "20px",
                        borderRadius: "20px",
                        backgroundColor: circleOutline,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "relative",
                        float: "right",
                        marginTop: "-5px",
                        marginRight: "-5px",

                    }}>
                        <div style={{
                            position: "absolute",
                            top: -20
                        }}>
                            {value}
                        </div>
                        <div style={{
                            height: "10px",
                            width: "10px",
                            borderRadius: "10px",
                            backgroundColor: "#fff"
                        }}></div>
                        <div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
