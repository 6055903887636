import * as React from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { Menu, MenuItem, Popper } from "@mui/material";

export default function MouseOverPopover({
  head = "",
  text = "",
  renderUI = null,
  vertical = "top",
  horizontal = "left",
  ...props
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div {...props}>
      <Typography
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        className="d-flex flex-column align-items-center justify-content-center"
      >
        {head}
      </Typography>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical,
          horizontal,
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography sx={{ p: 1 }}>{renderUI ?? text}</Typography>
      </Popover>
    </div>
  );
}

export function OptionsPopper({
  open,
  options,
  anchorEl,
  placement = "bottom-start",
  handleClose,
  handleSelect,
}) {
  return (
    <Menu
      id="long-menu"
      MenuListProps={{
        "aria-labelledby": "long-button",
      }}
      open={open}
      anchorEl={anchorEl}
      placement={placement}
      onClose={handleClose}
      PaperProps={{
        style: {
          maxHeight: 48 * 4.5,
          width: "25ch",
        },
      }}
    >
      {options.map((item, idx) => (
        <MenuItem
          key={idx}
          onClick={() => {
            handleSelect(item);
            handleClose();
          }}
          value={item?.value ?? item}
        >{item?.name ?? item}
        </MenuItem>
      ))}
    </Menu>
  );
}
