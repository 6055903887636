import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import LoadingOver from "../Loading";
import {
  fetchRefreshCollectionData,
  updateDPDCollection,
  updateCollectionOutstandingAmount,
  updateAnchorPercentage,
} from "../../api";

const reconsileInit = {
  collections: { checked: false, fun: fetchRefreshCollectionData },
  DPD_and_customer_profile_percentage: {
    checked: false,
    fun: updateDPDCollection,
  },
  collection_outstanding_amount: {
    checked: false,
    fun: updateCollectionOutstandingAmount,
  },
  anchor_aggregate_percentage: { checked: false, fun: updateAnchorPercentage },
};

function ReconcileModal({ openReconcile, setOpenReconcile, fetchTableData }) {
  const [reconcileText, setReconcileText] = useState(null);
  const [selectReconcile, setSelectReconcile] = useState(reconsileInit);

  async function getCollections() {
    try {
      setReconcileText(`fetching collections table data`);
      if (localStorage.getItem("collectionFilterV2")) {
        await fetchTableData(
          1,
          50,
          "filter",
          JSON.parse(localStorage.getItem("collectionFilterV2"))
        );
      } else {
        await fetchTableData(1, 50);
      }
      toast("refetched data !!!");
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }

  async function handleSubmit(e) {
    setOpenReconcile(false);
    try {
      let checked = false;
      for (let item of Object.keys(selectReconcile || {})) {
        if (selectReconcile?.[item]?.checked) {
          checked = true;
          setReconcileText(`updating ${capitalize(item)}...`);
          const res = await selectReconcile?.[item]?.fun();
        }
      }
      if (checked) await getCollections();
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
    setReconcileText(null);
  }

  function capitalize(str) {
    return str
      ?.split("_")
      ?.map((item) => item?.[0]?.toUpperCase() + item?.slice(1)?.toLowerCase())
      ?.join(" ");
  }

  useEffect(() => {
    setSelectReconcile(reconsileInit);
  }, [openReconcile]);

  return (
    <>
      <Dialog
        open={openReconcile}
        onClose={() => setOpenReconcile(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>Choose Reconcile Option/s:</DialogTitle>
        <DialogContent>
          <FormGroup>
            {Object.keys(selectReconcile).map((item) => {
              return (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectReconcile?.[item]?.checked}
                      onChange={(event, value) => {
                        setSelectReconcile((prev) => ({
                          ...prev,
                          [item]: {
                            ...selectReconcile?.[item],
                            checked: value,
                          },
                        }));
                      }}
                    />
                  }
                  label={`Update ${capitalize(item)}`}
                />
              );
            })}
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="success" onClick={handleSubmit}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      {reconcileText !== null && <LoadingOver text={reconcileText} />}
    </>
  );
}

export default ReconcileModal;
