import { createContext, useEffect, useState } from "react";
import { io } from "socket.io-client";
import { baseURL } from "../api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export const SocketContext = createContext(null);

const SocketContextProvider = ({ children }) => {

    const [socket, setSocket] = useState();

    const navigate = useNavigate();

    useEffect(()=>{
        const url = baseURL.substring(0, baseURL.indexOf("/api/v1"));
        const _socket = io(url,{
            withCredentials:true,
            extraHeaders:{
                "application":"EMBEDDED-TOOLS"
            }
        });

        // Listen for successful connection
        _socket.on('connect', () => {
            toast("connected to server");
            console.log('Connected to server with socket ID:', _socket.id);
        });

        _socket.on('disconnect', () => {
            toast("Disconnected from server");
            console.log('Disconnected from server with socket ID:', _socket.id);
        });

        _socket.on("connect_error",(data)=>{
            if(data.message.startsWith("AUTH_ERROR")){
               navigate("/login",{
                state:{
                    from:"/pendency-tracker"
                }
               });
            }
            toast.error(data?.message || "");
        })

        _socket.on("error",(data)=>{
            toast.error(data?.message || "");
        })

        setSocket(_socket);
        
        return ()=>{
            _socket.removeAllListeners();
            _socket.disconnect();
            setSocket(null);
        }
    },[])

    return (
        <SocketContext.Provider value={socket} >
            {children}
        </SocketContext.Provider>
    )
}

export default SocketContextProvider;