import NotificationsIcon from "@mui/icons-material/Notifications";
import {
  Avatar,
  Badge,
  CircularProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Checkbox,
  Button,
  IconButton,
} from "@mui/material";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { GlobalContext } from "../../Context/GlobalContext";
import { UserContext } from "../../Context/UserContext";
import { getNotification, markReadNotification } from "../../api";
import EmptyImg from "../../assets/empty.png";
import type0Img from "../../assets/type0.svg";
import type1Img from "../../assets/type1.svg";
import NotiFilter from "../NotiFilter";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import CloseIcon from "@mui/icons-material/Close";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Notifications = () => {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const [notification, setNotification] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const { notiCountState } = useContext(GlobalContext);
  const [notiCount, setNotiCount] = notiCountState;
  const [personName, setPersonName] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);
  const [filters, setFilters] = useState(null);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(typeof value === "string" ? value.split(",") : value);
  };

  const fetchNotifications = async (page, initial = false) => {
    setLoading(true);
    try {
      let params = `data=true&page=${page}${initial ? "&count=true" : ""}`;
      if (filters) {
        params += `&filters=${JSON.stringify(filters)}`;
      }
      let { data } = await getNotification(params);

      if (filters && initial) {
        setNotification(data?.notifications || []);
      } else {
        setNotification((prev) => [...prev, ...data?.notifications]);
      }
      setTotalCount(Math.ceil(data?.totalCount / 1));

      if (initial && !filters) {
        setNotiCount(data?.count);
      }
    } catch (err) {
      toast.error(
        err?.response?.data?.message || "Cannot load notification. Try again"
      );
    }
    setLoading(false);
  };

  const markRead = async (id) => {
    try {
      let { data } = await markReadNotification({ id });
    } catch (err) {
      console.log(err?.response?.data?.message);
    }
  };

  useEffect(() => {
    fetchNotifications(1, true);
  }, [filters]);

  const NotiCard = ({ val, index }) => {
    const [img, setImg] = useState(val?.notification?.image);
    return (
      <ListItem divider disablePadding>
        <ListItemButton
          sx={
            !val?.send_to?.[index]?.is_read ? { background: "#0061FE14" } : {}
          }
          onClick={() => {
            if (val?.type === "WHATSAPP_REPLY") {
              if (
                val?.data?.sub_type === "CUSTOMER" &&
                val?.data?.mobile_number
              ) {
                navigate(`/collections-v2?mobile=${val?.data?.mobile_number}`, {
                  state: {
                    tab: 1,
                    application_id: val?.data?.application_id,
                  },
                });
                if (!val?.send_to?.[index]?.is_read) {
                  markRead(val?._id);
                  setNotiCount(notiCount - 1);
                }
              }
            }

            if (val?.type === "TWILIO_TRANSCRIPTION") {
              if (val?.data?.mobile_number && val?.data?.application_id) {
                navigate(`/collections-v2?mobile=${val?.data?.mobile_number}`, {
                  state: {
                    tab: 2,
                    application_id: val?.data?.application_id,
                  },
                });
                if (!val?.send_to?.[index]?.is_read) {
                  markRead(val?._id);
                  setNotiCount(notiCount - 1);
                }
              }
            }

            if (["AGENT_LOGIN", "ANCHOR_LOGIN"].includes(val?.type)) {
              navigate(
                `/${
                  val?.type === "AGENT_LOGIN"
                    ? "update-agent"
                    : "update-anchor-v2"
                }/${val?.data?.user_id}`,
                {
                  state: {
                    path: "/notifications",
                  },
                }
              );
              if (!val?.send_to?.[index]?.is_read) {
                markRead(val?._id);
                setNotiCount(notiCount - 1);
              }
            }

            if (["FILE_LOGIN", "FILE_UNDER_REVIEW"].includes(val?.type)) {
              navigate(`/view-application/${val?.data?.application_id}`, {
                state: {
                  path: "/notifications",
                },
              });
              if (!val?.send_to?.[index]?.is_read) {
                markRead(val?._id);
                setNotiCount(notiCount - 1);
              }
            }
          }}
        >
          <ListItemAvatar>
            <Badge
              variant={val?.send_to?.[index]?.is_read ? "" : "dot"}
              color="error"
            >
              <Avatar src={val?.notification?.image}>
                <NotificationsIcon />
              </Avatar>
            </Badge>
          </ListItemAvatar>
          <ListItemText
            primary={val?.notification?.title}
            secondary={
              <>
                <div>
                  {typeof val?.notification?.body === "string"
                    ? val?.notification?.body
                    : ""}
                </div>
                {val?.data?.res_type === 1 && (
                  <div>
                    <Button
                      size="small"
                      className="mt-2"
                      variant={"outlined"}
                      onClick={(e) => {
                        e.stopPropagation();
                        window.open(
                          `/view-application/${val?.data?.application_id}/5?redirect=present`
                        );
                      }}
                    >
                      Present Nach
                    </Button>
                  </div>
                )}
                <div className="mt-2">{moment(val?.createdAt).fromNow()}</div>
              </>
            }
          />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 10,
            }}
          >
            {(val?.data || {})?.hasOwnProperty("res_type") && (
              <div>
                <img
                  width="50px"
                  src={val?.data?.res_type === 1 ? type1Img : type0Img}
                />
              </div>
            )}
            {["WHATSAPP_REPLY", "TWILIO_TRANSCRIPTION"]?.includes(val?.type) &&
              val?.data?.nbfc_logo && (
                <div>
                  <embed src={val?.data?.nbfc_logo} width={70}></embed>
                </div>
              )}
          </div>
        </ListItemButton>
      </ListItem>
    );
  };

  useEffect(()=>{
    setOpenFilter(!openFilter);
    setFilters(null);
  },[])

  return (
    <div
      style={{
        marginTop: "65px",
        position: "relative",
        background:"#fff"
      }}
    >
      <div className="notifications-header d-flex bg-white">
        <div className="w-100 d-flex position-relative d-flex align-items-center justify-content-between">
          <IconButton
            onClick={() => {
              setOpenFilter(!openFilter);
              setFilters(null);
            }}
            className="z-2"
          >
            {!openFilter ? <FilterAltIcon /> : <CloseIcon />}
          </IconButton>
          <span
            style={{
              display: "flex",
              fontSize: "20px",
              marginBottom: 0,
              color: "gray",
              alignItems: "center",
              marginRight: 20,
            }}
          >
            <NotificationsIcon className="me-1" /> Notifications
          </span>
          {openFilter && (
            <NotiFilter
              handleApply={(nbfcs, type, res_type) => {
                window.scrollTo({ top: 0, behavior: "smooth" });
                setPage(1);
                setFilters({
                  nbfcs,
                  type,
                  ...(res_type?.length === 2 ? {} : { res_type }),
                });
              }}
            />
          )}
        </div>
      </div>
      <div>
        <List sx={{ width: "100%" }}>
          <InfiniteScroll
            dataLength={notification.length}
            next={() => {
              setPage(page + 1);
              fetchNotifications(page + 1);
            }}
            hasMore={notification?.length < totalCount}
            loader={<LoadingComp />}
            endMessage={
              isLoading ? (
                <LoadingComp />
              ) : (
                notification?.length !== 0 && (
                  <p style={{ textAlign: "center", color: "gray" }}>
                    Loaded all the Notification 👍
                  </p>
                )
              )
            }
          >
            <>
              {notification?.map((val) => {
                const index = val?.send_to?.findIndex(
                  (item) => item?.recipient_id === user?.user_id
                );

                return <NotiCard key={val._id} val={val} index={index} />;
              })}
            </>
          </InfiniteScroll>
        </List>

        {notification?.length === 0 && !isLoading && (
          <div className="mt-5">
            <div className="content-center">
              <img src={EmptyImg} alt="No notification" />
            </div>
            <p className="text-center" style={{ marginTop: "-20px" }}>
              No notifications yet!
            </p>
          </div>
        )}
      </div>
    </div>
  );
};


export const LoadingComp = () => {
  return (
    <div className="content-center w-100">
      <CircularProgress size={40} />
    </div>
  );
};

export default Notifications;
